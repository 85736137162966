import { v4 as uuidv4 } from 'uuid';

export const blubinaLink = 'https://blubina.de';
export const contactLink = 'contact@blubina.com';

export const navLinkList = [
    { link: '#main__challenges', txt: 'challenges' },
    { link: '#main__solutions', txt: 'solutions' },
    { link: '#main__products', txt: 'products' },
    { link: '#main__team', txt: 'team' },
    { link: '#main__partners', txt: 'partners' },
    { link: '#main__contact', txt: 'contact' }
];

export const productList = [
    {
        id: uuidv4(),
        name: 'blubinaCore',
        txt: 'Blubina Core',
        active: true,
        btnAction: (curLang) => window.open(`/${curLang}/product/blubina-core`, '_blank')
    },
    {
        id: uuidv4(),
        name: 'blubinaOrgCharts',
        txt: 'Blubina Org-Charts',
        active: true,
        btnAction: (curLang) => window.open(`/${curLang}/product/blubina-org-charts`, '_blank')
    },
    {
        id: uuidv4(),
        name: 'secureFolderSharing',
        txt: 'Secure Folder Sharing',
        active: true,
        btnAction: (curLang) => window.open(`/${curLang}/product/secure-folder-sharing`, '_blank')
    },
    {
        id: uuidv4(),
        name: 'uPointDMS',
        txt: 'uPoint DMS',
        active: true,
        btnAction: (curLang) => window.open(`https://upointdms.com/${curLang}`, '_blank')
    },
    {
        id: uuidv4(),
        name: 'pdfSplitter',
        txt: 'PDF Splitter',
        active: true,
        btnAction: (curLang) => window.open(`/${curLang}/product/pdf-splitter`, '_blank')
    }
];

export const hamburgerMenuList = [
    {
        id: uuidv4(),
        name: 'services',
        txt: 'main.nav.services',
        btnAction: (curLang) => window.open(`/${curLang}/services`, '_blank'),
        childrenList: null
    },
    {
        id: uuidv4(),
        name: 'products',
        txt: 'main.nav.products',
        btnAction: null,
        childrenList: [
            {
                id: uuidv4(),
                name: 'blubinaCore',
                txt: 'product.blubinaCore.main.title',
                btnAction: (curLang) => window.open(`/${curLang}/product/blubina-core`, '_blank')
            },
            {
                id: uuidv4(),
                name: 'blubinaOrgCharts',
                txt: 'product.orgCharts.main.title',
                btnAction: (curLang) => window.open(`/${curLang}/product/blubina-org-charts`, '_blank')
            },
            {
                id: uuidv4(),
                name: 'secureFolderSharing',
                txt: 'product.SFS.main.title',
                btnAction: (curLang) => window.open(`/${curLang}/product/secure-folder-sharing`, '_blank')
            },
            {
                id: uuidv4(),
                name: 'uPointDMS',
                txt: 'product.uPointDMS.main.title',
                btnAction: (curLang) => window.open(`https://upointdms.com/${curLang}`, '_blank')
            },
            {
                id: uuidv4(),
                name: 'pdfSplitter',
                txt: 'product.pdfSplitter.main.title',
                btnAction: (curLang) => window.open(`/${curLang}/product/pdf-splitter`, '_blank')
            }
        ]
    }
];
